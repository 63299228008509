'use strict';

(function () {
	// ---------------------------------------------------------------
	// KV
	let swiperKv = null;
	function buildKvSwiper() {
		const target = '#kv-swiper';
		swiperKv = new Swiper(target, {
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			lazy: true,
			effect: 'fade',
			fadeEffect: {
				crossFade: true,
			},
			slidesPerView: 1,
			spaceBetween: 5,
			speed: 3200,
			watchSlidesProgress: true,
		});
	}

	window.addEventListener('load', buildKvSwiper);
	// ---------------------------------------------------------------

	// DESIGNER
	let swiperDesigner = null;
	function buildDesignerSwiper() {
		const target = '#designer-swiper';
		swiperDesigner = new Swiper(target, {
			pagination: {
				el: `${target} .swiper-pagination`,
			},
			navigation: {
				nextEl: `.sec-designer .swiper-button-next`,
				prevEl: `.sec-designer .swiper-button-prev`,
			},
			lazy: true,
			effect: 'fade',
			fadeEffect: {
				crossFade: true,
			},
			slidesPerView: 1,
			spaceBetween: 5,
			speed: 800,
			watchSlidesProgress: true,
		});
	}

	window.addEventListener('load', buildDesignerSwiper);

	// ---------------------------------------------------------------
  const $portfolio = document.querySelector('.portfolio_cont');
  let flag = false;

  function scrollFixed() {
    let ww = window.innerWidth;
    const $portfolioNav = document.querySelector('.portfolio_nav');
    const portfolioViewport = $portfolio.getBoundingClientRect();
    const hdHeight = document.querySelector('#hd').offsetHeight;

    if (ww >= 1024) {

      if (portfolioViewport.top - hdHeight > 0) {
        flag = false;
        $portfolioNav.classList.add('is-fixed-top');
        $portfolioNav.classList.remove('is-fixed-bottom');
        $portfolioNav.style.removeProperty("position");
        $portfolioNav.style.removeProperty("top");
      }

      if (portfolioViewport.top - hdHeight <= 0 && portfolioViewport.bottom - hdHeight >= 0) {
        const { top } = $portfolioNav.getBoundingClientRect();

        if (!flag) {
          flag = true;
          $portfolioNav.style.position = 'fixed';
          $portfolioNav.style.top = top+'px';
          $portfolioNav.classList.remove('is-fixed-top');
          $portfolioNav.classList.remove('is-fixed-bottom');
        }
      }

      if (portfolioViewport.bottom - $portfolioNav.offsetHeight - hdHeight - 80 < 0) {
        flag = false;
        $portfolioNav.classList.add('is-fixed-bottom');
        $portfolioNav.classList.remove('is-fixed-top');
        $portfolioNav.style.removeProperty("position");
        $portfolioNav.style.removeProperty("top");
      }
    } else {
      if (portfolioViewport.top + hdHeight <= 0 && portfolioViewport.bottom - hdHeight >= 0) {
        $portfolioNav.classList.add('is-show');
      } else {
        $portfolioNav.classList.remove('is-show');
      }
    }
  }

	window.addEventListener('scroll', scrollFixed);

  // ---------------------------------------------------------------
	// 動態
  let $rellaxPic = null;

	function buildRellax() {
		$rellaxPic = new Rellax('.js-rellax-pic', {
			center: true,
		});
	}

	if ($('.js-rellax-pic').length) {
		window.addEventListener('load', buildRellax);
	}

  

  const $tabEls = document.querySelectorAll('#portfolioTab button[data-bs-toggle="tab"]');

  $tabEls.forEach((tab) => {
    tab.addEventListener('shown.bs.tab', function (event) {
      $rellaxPic.refresh();
    });
  })
})();
